import React, { ReactElement } from "react";
import { Box } from "@chakra-ui/react";
import { getMonstersPublished } from "../../api/monster";
import { MyNavbar } from "../../components/common/navbar/MyNavbar";
import { CardGridMonster } from "../../components/list_homebrew/CardGrid";
import { monsterDataConverter } from "../../components/list_homebrew/DataConverters";
import { COLLECTIONS } from "../../constants/General";
import { useApiClient } from "../../context/ApiClient";

export default function Creations(): ReactElement {
  const apiClient = useApiClient();

  return (
    <Box minH="100vh" backgroundColor="monster.background">
      <MyNavbar />
      <CardGridMonster
        title="Explore Monsters"
        showFilters={true}
        apiMethod={(filterValues, paginationOptions) => getMonstersPublished(apiClient.getClient(), filterValues, paginationOptions)}
        dataConverter={monsterDataConverter}
        contentType="public"
        collection={COLLECTIONS.Monsters}
      />
    </Box>
  );
}
